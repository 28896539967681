import { generateQuery } from '@/helpers/pathName';
import { PATH_NAME } from 'configs/pathName';
import { Typography } from 'design-system/components/Typography';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const RegisterHere = (
  props: React.DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >,
  ref: ForwardedRef<HTMLParagraphElement>
) => {
  const router = useRouter();

  return (
    <Typography
      {...props}
      ref={ref}
      variant="button2"
      className={twMerge(
        'mx-auto max-w-[452px] text-Text/LightBackground/Secondary',
        props.className
      )}
    >
      Bạn chưa có tài khoản? Đăng ký&nbsp;
      <span
        onClick={() =>
          router.push({
            pathname: PATH_NAME.REGISTER,
            query: generateQuery(router),
          })
        }
        className="cursor-pointer text-Primary/Main"
      >
        tại đây
      </span>
      .
    </Typography>
  );
};

export default forwardRef(RegisterHere);
