import ForgetPasswordTypo from '@/components/elements/auth/ForgetPasswordTypo';
import LoginFormWrapper from '@/components/elements/auth/LoginFormWrapper';
import { QueryKeys } from '@/helpers/pathName';
import { ELoginType } from '@/interfaces/auth.interface';
import { serverSideIsLoggedIn } from 'common/authFunction';
import { PATH_NAME } from 'configs/pathName';
import AuthLayout from 'layouts/AuthLayout';
import { GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import { NextPageWithLayout } from './page';

const LoginFormSection = dynamic(
  () => import('@/components/elements/auth/LoginFormSection')
);
const LoginWithOTPSection = dynamic(
  () => import('@/components/elements/auth/LoginWithOTPSection')
);

const LoginPage: NextPageWithLayout<{ type: ELoginType }> = ({
  type = ELoginType.PASSWORD,
}) => {
  return (
    <>
      {type === ELoginType.PASSWORD ? (
        <LoginFormWrapper type={type}>
          <LoginFormSection
            forgetPassword={<ForgetPasswordTypo className="xl:!mt-6" />}
          />
        </LoginFormWrapper>
      ) : (
        <LoginWithOTPSection />
      )}
    </>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { isLoggedIn } = serverSideIsLoggedIn(context);

  const redirectUrl = context.query[QueryKeys.redirectUrl];
  const nfcId = context.query[QueryKeys.nfcId];

  if (isLoggedIn) {
    return {
      redirect: {
        destination: nfcId
          ? `${PATH_NAME.LIBRARY}?nfcId=${nfcId}`
          : (redirectUrl as string) || PATH_NAME.LIBRARY,
        permanent: false,
      },
    };
  }

  const type = context.query[QueryKeys.type] ?? ELoginType.PASSWORD;

  return {
    props: {
      type,
    },
  };
};

LoginPage.getLayout = (page) => {
  return (
    <AuthLayout
      title={'Đăng nhập'}
      description={'Đăng nhập để tiếp cận nội dung Y tế dành cho bạn'}
    >
      {page}
    </AuthLayout>
  );
};

export default LoginPage;
