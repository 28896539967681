import { generateQuery } from '@/helpers/pathName';
import { PATH_NAME } from 'configs/pathName';
import { TypographyProps } from 'design-system/components/Typography';
import Typography from 'design-system/components/Typography/Typography';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

const ForgetPasswordTypo = (
  props: Omit<TypographyProps, 'variant' | 'onClick'>
) => {
  const router = useRouter();

  return (
    <Typography
      {...props}
      onClick={() =>
        router.push({
          pathname: PATH_NAME.RESET_PASSWORD,
          query: generateQuery(router),
        })
      }
      variant="button2"
      className={twMerge(
        '!mt-4 ml-auto w-fit cursor-pointer text-right text-Primary/Main',
        props.className
      )}
    >
      Quên mật khẩu
    </Typography>
  );
};

export default ForgetPasswordTypo;
