import { useRouter } from 'next/router';
import { GoogleLogin } from 'react-google-login';
// import { PATH_NAME } from '../../../../configs/pathName';
import Button from 'design-system/components/Button/Button';
import Typography from 'design-system/components/Typography/Typography';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { handleSendGoogleIdToken } from '../../../../common/handleSendGoogleIdToken';
import { ILoginGoogleButton } from '../../../../interfaces/components/button.interface';
import AuthContext from '../../../../state/auth/AuthContext';
import { useModal } from '../../../../state/modal-provider/ModalProvider';

const AcceptTermTextGoogle = dynamic(
  () => import('../../../policyText/AcceptTermTextGoogle'),
  {
    ssr: false,
  }
);

const LoginGoogleButton: React.FC<ILoginGoogleButton> = ({
  className,
  disableRedirect = false,
  successCallback = (userId: string) => {},
  hideTerms = false,
}) => {
  const router = useRouter();
  const { toast } = useModal();
  const { setLoading, setUserEmail } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const successLoginGoogle = (res: any) => {
    const { tokenId } = res;
    handleSendGoogleIdToken({
      idToken: tokenId,
      setLoading,
      toast,
      router,
      disableRedirect,
      successCallback: (userId) => {
        queryClient.refetchQueries({ active: true });
        successCallback(userId);
      },
      setUserEmail,
    });
  };

  const failLoginGoogle = async ({ error }: { error: string }) => {
    if (
      error !== 'popup_closed_by_user' &&
      error !== 'idpiframe_initialization_failed'
    ) {
      toast.error({
        content: 'Có lỗi xảy ra khi tiếp tục với Google, vui lòng thử lại sau!',
        infinite: true,
      });
    }
  };

  return (
    <div className="w-full">
      {!hideTerms && <AcceptTermTextGoogle />}
      <GoogleLogin
        clientId={process.env.GOOGLE_ID || ''}
        render={(renderProps) => (
          <Button
            {...renderProps}
            className="w-full"
            size="medium"
            emphasis="medium"
            startIcon={{
              name: 'google',
            }}
          >
            <Typography className="text-Primary/Main" variant="button2">
              Tiếp tục với Google
            </Typography>
          </Button>
        )}
        onSuccess={successLoginGoogle}
        onFailure={failLoginGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
};

export default LoginGoogleButton;
