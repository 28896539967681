import LoginGoogleButton from '@/components/buttons/auth/google/LoginGoogleButton';
import NavigateToLoginType from '@/components/buttons/auth/NavigateToLoginType';
import { ELoginType } from '@/interfaces/auth.interface';
import { Divider } from 'design-system/components/Divider';
import { Typography } from 'design-system/components/Typography';
import { PropsWithChildren } from 'react';
import RegisterHere from '../register/RegisterHere';

const LoginFormWrapper = ({
  children,
  type,
}: PropsWithChildren<{
  /**current type */
  type: ELoginType;
}>) => {
  return (
    <>
      <div className="mx-auto w-full max-w-[452px] space-y-8 text-Text/LightBackground/Secondary xl:space-y-6">
        {children}
        <div className="flex items-center gap-[15px] text-Text/LightBackground/Secondary">
          <Divider className="flex-1" />
          <Typography variant={'body3'} className="flex-shrink-0">
            Hoặc đăng nhập bằng
          </Typography>
          <Divider className="flex-1" />
        </div>
        <div className="space-y-2">
          <NavigateToLoginType
            type={
              type === ELoginType.PASSWORD
                ? ELoginType.OTP
                : ELoginType.PASSWORD
            }
          />
          <LoginGoogleButton hideTerms />
        </div>
      </div>
      <RegisterHere />
    </>
  );
};

export default LoginFormWrapper;
