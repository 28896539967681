import { generateQuery, QueryKeys } from '@/helpers/pathName';
import { ELoginType } from '@/interfaces/auth.interface';
import { PATH_NAME } from 'configs/pathName';
import Button from 'design-system/components/Button/Button';
import { GenericIconName, IconVariant } from 'design-system/components/Icon';
import Typography from 'design-system/components/Typography/Typography';
import { useRouter } from 'next/router';

const NavigateToLoginType = ({ type }: { type: ELoginType }) => {
  const router = useRouter();

  const hashContentAndIconDueToType: {
    [key in ELoginType]: {
      content: string;
      icon: {
        name: GenericIconName;
        variant: IconVariant;
      };
    };
  } = {
    [ELoginType.OTP]: {
      content: 'Tiếp tục với Zalo OTP',
      icon: {
        name: 'zalo',
        variant: 'outline',
      },
    },
    [ELoginType.PASSWORD]: {
      content: 'Tiếp tục với Email hoặc Số điện thoại',
      icon: {
        name: 'call',
        variant: 'solid',
      },
    },
  };

  return (
    <Button
      onClick={() =>
        router.replace({
          pathname: PATH_NAME.LOGIN,
          query: {
            ...generateQuery(router),
            [QueryKeys.type]: type,
          },
        })
      }
      className="w-full"
      size="medium"
      emphasis="medium"
      startIcon={{
        name: hashContentAndIconDueToType[type].icon.name,
        variant: hashContentAndIconDueToType[type].icon.variant,
      }}
    >
      <Typography className="text-Primary/Main" variant="button2">
        {hashContentAndIconDueToType[type].content}
      </Typography>
    </Button>
  );
};

export default NavigateToLoginType;
